import {
  Box,
  Heading,
  Stack,
  Divider,
  Container,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import twoClayHemispheres from '../../images/two-clay-hemispheres.png';
import { Footer } from '../Footer';

export interface DashboardTemplateProps {
  children: ReactNode;
}

export const DashboardTemplate: React.FC<DashboardTemplateProps> = ({
  children,
}) => (
  <Box bgColor="deepSpace.900" height="100vh" width="100%" alignContent="center" justifyContent="center">
    <Box
      px={['0.5rem', '1rem', '1.5rem', '2rem']}
      style={{
        backgroundImage: `url("${twoClayHemispheres}"), linear-gradient(to right bottom, #001A1A, #000F0F)`,
      }}
      sx={{
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
      color="white"
    >

      <Stack spacing={4} width="100%" direction="column">
        <Stack
          p={5}
          alignItems="center"
          justifyContent={{
            base: 'flex-start',
            md: 'space-around',
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Stack
            textAlign="center"
            mb="2"
          >
            <Heading size="lg">
              Neptune Triton
            </Heading>
          </Stack>
        </Stack>

        <Divider />
      </Stack>
      {children}
      <Container>
        <Footer />
      </Container>
    </Box>
  </Box>
);
