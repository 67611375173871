import {
  Alert, AlertDescription, AlertIcon, AlertTitle, Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../app/hooks';

import { Loading } from './Loading';
import { DashboardTemplate } from './page_templates';
import NotWithNeptuneYet from './NotWithNeptuneYet';
import { useGetCustomerQuery } from '../services/eventsService';

// Triton Redirect Component
const TritonRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect after 3 seconds
    const timer = setTimeout(() => {
      window.location.href = 'https://www.neptune.net.au/portal/internet';
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Alert status="info" backgroundColor="blue.100" marginTop="20px">
      <AlertIcon />
      <AlertTitle color="black">Triton is deprecated.</AlertTitle>
      <AlertDescription color="black">
        You&apos;re being redirected to our new customer service portal.
      </AlertDescription>
    </Alert>
  );
};

const Dashboard = () => {
  const token = useAppSelector((state) => state.auth.token);
  const {
    data: customerData, error: customerError, isLoading: isCustomerLoading, refetch,
  } = useGetCustomerQuery('', {
    pollingInterval: 15000,
    skip: !token,
  });

  const [focused, setFocused] = useState('');

  if (customerError) {
    return (
      <>
        <Alert
          textAlign="center"
          colorScheme="pink"
          w="100%"
          p={4}
          color="black"
        >
          <AlertIcon />
          <AlertTitle textAlign="center">
            <a href="https://neptune.net.au/">
              Error occurred
            </a>
          </AlertTitle>
          <AlertDescription>{'data' in customerError ? customerError.data as string : 'unknown'}</AlertDescription>
        </Alert>
        {!customerData?.subscriptions && <NotWithNeptuneYet /> }
      </>
    );
  }

  if (isCustomerLoading) {
    return <Loading />;
  }

  return (
    <DashboardTemplate>
      <Stack direction={['column', 'column', 'column', 'row']} alignItems="ceneter" justifyContent="center">
        <TritonRedirect />
      </Stack>
    </DashboardTemplate>
  );
};

export default Dashboard;
